export function normalizeNumber(number) {
  if (number === undefined || number === null) {
    return ''
  }
  return normalizeString((number * 100).toString())
}

export function normalizeString(value) {
  const prefix = 'R$ '

  let cleanValue = value?.replace(/\D/g, '') || ''
  if (cleanValue.charAt(0) === '0') {
    cleanValue = cleanValue.replace('0', '')
  }

  const cleanValueLength = cleanValue.length
  const decimal =
    cleanValue.substr(cleanValueLength - 2).padStart(2, '0') || '00'
  const integer = cleanValue.substr(0, cleanValueLength - 2) || '0'

  const integerFormatted = integer
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    .substr(0, 21)

  return `${prefix}${integerFormatted},${decimal}` || ''
}

export function unormalizeString(value) {
  if (!value) return 0
  const unormalized = value
    .replace('R$', '')
    .replace(/\./g, '')
    .replace(/\s/g, '')
    .replace(/,/g, '.')

  return Number(unormalized)
}
