import React from 'react'
import { bool, number, object, shape, string } from 'prop-types'

import Description from './components/Description'
import InfoContainer from './components/InfoContainer'
import PreTitle from './components/PreTitle'
import Slider from './components/Slider'
import { StyledReclameAquiAward } from './components/Stamps'
import Title from './components/Title'
import Wrapper from './components/Wrapper'

const propTypes = {
  description: string,
  isHidePreTitle: bool,
  preTitle: string,
  preTitleAs: string,
  showReclameAquiAward: bool,
  slider: shape({
    buttonCta: object,
    buttonCtaLabel: string,
    customStepValue: number,
    defaultLoanValue: number,
    title: string,
    valueConfig: object,
  }),
  title: string,
  titleAs: string,
}

const defaultProps = {
  showReclameAquiAward: true,
}

const HeroWithSlider = ({
  description,
  isHidePreTitle,
  preTitle,
  preTitleAs,
  showReclameAquiAward,
  slider,
  title,
  titleAs,
  ...others
}) => {
  const showReclameAqui =
    showReclameAquiAward === null
      ? defaultProps.showReclameAquiAward
      : showReclameAquiAward

  return (
    <Wrapper {...others}>
      <InfoContainer>
        <PreTitle as={preTitleAs} isHide={isHidePreTitle}>
          {preTitle}
        </PreTitle>
        <Title as={titleAs}>{title}</Title>
        <Description variant={{ _: 'bodyMdRegular', '5xl': 'bodyXlLight' }}>
          {description}
        </Description>
      </InfoContainer>
      <Slider {...slider} />
      {showReclameAqui && (
        <StyledReclameAquiAward data-testid="award" variant="black" />
      )}
    </Wrapper>
  )
}

HeroWithSlider.propTypes = propTypes
HeroWithSlider.defaultProps = defaultProps

export default HeroWithSlider
