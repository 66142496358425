import { styled } from '@creditas-ui/system'
import { Typography as CreditasTypography } from '@creditas-ui/typography'
import { media, selectTheme } from '@creditas-ui/utilities'

const Typography = styled(CreditasTypography)`
  margin-bottom: ${selectTheme('spacingBottom.10xl')};

  ${media.up('4xl')} {
    margin-bottom: 0;
  }
}
`

export { Typography }
