import { InputOpened } from '@creditas-ui/input'
import { styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'
import { getTypography, media, selectTheme } from '@creditas-ui/utilities'

import { InputSlider } from 'components/RangeSlider'

const Container = styled.form`
  background-color: ${selectTheme('colors.neutral.0')};
  padding: ${selectTheme('spacingY.10xl')} ${selectTheme('spacingX.8xl')};
  border-radius: ${selectTheme('radius.4xl')};
  border: 1px solid ${selectTheme('colors.neutral.10')};
  box-shadow: ${selectTheme('shadow.lg')};
  max-width: 100%;
  z-index: 1;
  position: sticky;

  ${media.up('5xl')} {
    padding: ${selectTheme('spacingY.10xl')} ${selectTheme('spacingX.6xl')};
    width: 288px;
  }
`

const Input = styled(InputOpened)`
  text-align: center;
  margin-bottom: ${selectTheme('spacingBottom.6xl')};
  height: 40px;

  ${media.up('5xl')} {
    height: 40px;
  }

  &,
  &::placeholder {
    ${getTypography('headingLgRegular')}
  }
`

const RangeSlider = styled(InputSlider)`
  min-height: 48px;

  &::-webkit-slider-thumb {
    box-shadow: ${selectTheme('shadow.sm')};
  }

  &::-moz-range-thumb {
    box-shadow: ${selectTheme('shadow.sm')};
  }

  &::-ms-thumb {
    box-shadow: ${selectTheme('shadow.sm')};
  }
`

const Title = styled(Typography)`
  color: ${selectTheme('colors.neutral.90')};
  display: block;
  margin-bottom: ${selectTheme('spacingBottom.8xl')};
  text-align: center;
  width: 100%;

  ${media.up('5xl')} {
    color: ${selectTheme('colors.neutral.80')};
    margin-bottom: ${selectTheme('spacingBottom.10xl')};
  }
`

export { Container, Input, RangeSlider, Title }
