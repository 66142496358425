import React from 'react'
import { node, object } from 'prop-types'

import { Container, Grid, Section } from './styles'

const propTypes = {
  children: node,
  currentSectionRef: object,
}

const Wrapper = ({ children, currentSectionRef, ...others }) => {
  return (
    <Section {...others} ref={currentSectionRef}>
      <Container>
        <Grid>{children}</Grid>
      </Container>
    </Section>
  )
}

Wrapper.propTypes = propTypes

export default Wrapper
