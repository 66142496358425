import React from 'react'
import { string } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  children: string,
  color: string,
}

const defaultProps = {
  color: 'neutral.80',
}

const PreTitle = ({ children, ...others }) => {
  if (!children) return null
  return (
    <Typography
      {...others}
      color="neutral.80"
      variant={{ _: 'exceptionSmBold', '5xl': 'exceptionMdBold' }}
    >
      {children}
    </Typography>
  )
}

PreTitle.propTypes = propTypes
PreTitle.defaultProps = defaultProps

export default PreTitle
