import { styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/utilities'

import { Cta } from 'components/Cta'

const StyledCta = styled(Cta)`
  text-decoration: none;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: ${selectTheme('shadow.xs')};
  white-space: nowrap;
  width: 100%;
`

export { StyledCta }
