import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

const Shape = styled.img`
  grid-area: slider;
  object-fit: contain;
  position: absolute;
  z-index: 0;
  bottom: -21px;
  right: -165px;
  width: 268px;

  ${media.up('5xl')} {
    top: 56px;
    left: 90px;
    width: 352px;
    bottom: auto;
  }
`

export { Shape }
