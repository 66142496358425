import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import ReclameAquiAward from 'components/ReclameAquiAward'

export const StyledReclameAquiAward = styled(ReclameAquiAward)`
  align-items: center;
  grid-area: stamps;
  margin-bottom: ${selectTheme('spacingBottom.10xl')};
  margin-top: 32px;
  width: 100%;

  p {
    color: ${selectTheme('colors.neutral.80')};
  }

  ${media.up('4xl')} {
    p {
      max-width: 360px;
    }
  }
`
