import { css, styled } from '@creditas-ui/system'
import { Typography as CreditasTypography } from '@creditas-ui/typography'
import { selectTheme } from '@creditas-ui/utilities'

const Typography = styled(CreditasTypography)(({ isHide, theme }) =>
  css(`
  text-transform: uppercase;
  margin-bottom: ${selectTheme('spacingBottom.3xl')({ theme })};
  display: ${isHide ? 'none' : 'visible'}
`),
)

export { Typography }
