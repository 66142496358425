import React from 'react'
import { oneOf } from 'prop-types'

import autoShape from '../../assets/auto_shape.svg'
import homeShape from '../../assets/home_shape.svg'

import { Shape } from './styles'

export const propTypes = {
  product: oneOf(['HOME', 'AUTO']),
}

const defaultProps = {
  product: 'HOME',
}

const PRODUCT_SHAPES = {
  HOME: homeShape,
  AUTO: autoShape,
}

const ProductShape = ({ product, ...others }) => {
  const productForShape = product || defaultProps.product
  const shape = PRODUCT_SHAPES[productForShape]
  return <Shape src={shape} {...others} />
}

ProductShape.propTypes = propTypes
ProductShape.defaultProps = defaultProps
export default ProductShape
