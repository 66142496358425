import React from 'react'
import { func, object, string } from 'prop-types'

import { ButtonPrimaryWithArrow } from '@creditas-ui/button'
import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { StyledCta } from './styles'

const propTypes = {
  buttonCta: object,
  label: string,
  onClick: func,
  productName: string,
}

const defaultProps = {
  buttonCta: {},
  onClick: () => {},
}

const ButtonCta = ({ buttonCta, label, onClick, productName, ...others }) => {
  const { triggerEvent } = useTriggerEvents()

  if (!buttonCta) return null

  const analyticEvent = {
    label: `${productName} | ${label}`,
    pageInteraction: buttonCta?.type !== 'link',
  }

  const handleOnClick = () => {
    triggerEvent(analyticEvent)
    onClick()
  }

  return (
    <StyledCta
      element={ButtonPrimaryWithArrow}
      width="flexible"
      size="large"
      onClick={handleOnClick}
      isButtonElement
      {...buttonCta}
      {...others}
    >
      {label}
    </StyledCta>
  )
}

ButtonCta.propTypes = propTypes
ButtonCta.defaultProps = defaultProps

export default ButtonCta
