import React from 'react'
import { string } from 'prop-types'

import { legacy } from 'ui/themes/legacy'

import { ErrorContainer, ErrorMessage, ErrorTitle } from './styles'

const propTypes = {
  description: string,
  title: string,
}

const Errors = ({ description, title }) => {
  return (
    <ErrorContainer>
      {title && (
        <ErrorTitle
          as="span"
          variant="bodySmRegular"
          color={legacy.legacyColors.error60}
        >
          {title}
        </ErrorTitle>
      )}
      {description && (
        <ErrorMessage
          role="alert"
          as="span"
          variant="bodySmRegular"
          color="neutral.60"
        >
          {description}
        </ErrorMessage>
      )}
    </ErrorContainer>
  )
}

Errors.propTypes = propTypes

export default Errors
