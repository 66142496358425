export function getValidLoanValueFromRangeConfig(value, min, max) {
  if (value === undefined || value === null) return undefined
  if (min <= value && value <= max) return value
  else return min
}

export function getValidStepValueFromRangeValueConfig(
  value,
  defaultStepValue,
  max,
) {
  if (!value || value >= max || value < 0) return defaultStepValue
  else return value
}
