import React, { useEffect } from 'react'

const useInputSlider = ({ max, min, onChange, value }) => {
  const [range, setRange] = React.useState(value)

  useEffect(() => {
    setRange(value)
  }, [value])

  const getFilledPercentage = currentValue => {
    const interval = max - min
    const start = currentValue - min
    const percentage = (start * 100) / interval

    return percentage
  }

  const onInputChange = event => {
    const currentValue = event.target.value
    setRange(currentValue)

    if (onChange) {
      onChange({
        value: currentValue,
        filled: getFilledPercentage(currentValue),
      })
    }
  }

  return { range, filled: getFilledPercentage(range), onInputChange }
}

export { useInputSlider }
