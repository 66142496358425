import React from 'react'
import { number, object, oneOf, shape, string } from 'prop-types'

import Form from './components/Form'
import ProductShape from './components/ProductShape'
import Wrapper from './components/Wrapper'

export const propTypes = {
  buttonCta: object,
  buttonCtaLabel: string,
  customStepValue: number,
  defaultLoanValue: number,
  title: string,
  valueConfig: shape({
    product: oneOf(['HOME', 'AUTO']),
    defaultStepValue: number,
    max: number,
    maxErrorMessage: string,
    min: number,
    minErrorMessage: string,
    stepErrorMessage: string,
  }),
}

const Slider = ({ valueConfig, ...others }) => {
  return (
    <Wrapper>
      {valueConfig && <ProductShape product={valueConfig.product} />}
      {valueConfig && <Form {...others} {...valueConfig} />}
    </Wrapper>
  )
}

Slider.propTypes = propTypes
export default Slider
