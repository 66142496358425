import React from 'react'
import { func, number } from 'prop-types'

import { useInputSlider } from './useInputSlider'

import { Input } from './styles'

const propTypes = {
  /**
   The minimum allowed value. If the `value` prop is not specified, the `min` will be setted as initial value. Important: it should not be equal to max.
  */
  max: number,
  min: number,
  /**
   The maximum allowed value. Important: it should not be equal to min.
  */
  /**
 Callback function called when the slider loses focus.
 */
  onBlur: func,
  /**
  Callback function called when slider value change. It receives as argument an object with the current value and the filled percentage.
 */
  onChange: func,
  /**
   The legal number intervals (the specific interval which the slider can step through values). `min` and `max` attributes should be divisible by step.
  */
  step: number,
  /**
   The value of the slider.
  */
  value: number,
}

const defaultProps = {
  min: 0,
  max: 10,
  step: 1,
  value: 0,
  onChange: () => {},
  onBlur: () => {},
}

const InputSlider = React.forwardRef(
  ({ max, min, onBlur, onChange, step, value, ...props }, ref) => {
    const { filled, onInputChange, range } = useInputSlider({
      max,
      min,
      value,
      onChange,
    })

    return (
      <Input
        {...props}
        type="range"
        ref={ref}
        min={min}
        max={max}
        step={step}
        value={range}
        filled={filled}
        onChange={onInputChange}
        onBlur={onBlur}
      />
    )
  },
)

InputSlider.displayName = 'InputSlider'
InputSlider.propTypes = propTypes
InputSlider.defaultProps = defaultProps

export { InputSlider }
