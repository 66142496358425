import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

const Container = styled.div(
  ({ theme }) => css`
    grid-area: info;

    padding-top: ${selectTheme('spacingTop.6xl')({ theme })};

    ${media.up('5xl')} {
      max-width: 500px;
      padding-top: ${selectTheme('spacingTop.23xl')({ theme })};
    }
  `,
)

export { Container }
