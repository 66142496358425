import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

const Container = styled.div`
  grid-area: slider;
  position: relative;
  overflow: visible;

  ${media.up('5xl')} {
    padding-top: ${selectTheme('spacingTop.21xl')};
  }
`

export { Container }
