import { styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'
import { media, selectTheme } from '@creditas-ui/utilities'

const ErrorContainer = styled.div`
  text-align: center;
  margin-top: ${selectTheme('spacingTop.6xl')};
  margin-bottom: ${selectTheme('spacingTop.9xl')};
  min-height: 48px;

  &:empty {
    margin: 0;
  }

  ${media.up('5xl')} {
    min-height: 64px;
  }
`

const ErrorTitle = styled(Typography)`
  margin-bottom: ${selectTheme('spacingBottom.md')};
  display: block;
  width: 100%;
`

const ErrorMessage = styled(Typography)`
  display: block;
  width: 100%;
`

export { ErrorContainer, ErrorMessage, ErrorTitle }
