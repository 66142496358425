import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as CreditasContainer } from 'components/Container'

const Section = styled.section`
  background-color: ${selectTheme(`colors.neutral.10`)};
  overflow: hidden;
`

const Container = styled(CreditasContainer)`
  padding-bottom: ${selectTheme('spacingBottom.18xl')};

  ${media.up('5xl')} {
    padding-bottom: ${selectTheme('spacingBottom.21xl')};
  }
`

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  grid-template-areas:
    'info'
    'slider'
    'stamps';

  ${media.up('5xl')} {
    grid-gap: ${selectTheme('spacingX.6xl')};
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
      '. ${'info '.repeat(6)}${' slider'.repeat(5)}'
      '. ${'stamps '.repeat(6)}${' slider'.repeat(5)}';
  }
`

export { Section, Container, Grid }
