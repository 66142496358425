import React from 'react'
import { string } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  children: string,
  color: string,
}

const defaultProps = {
  color: 'neutral.60',
}

const Description = ({ children, ...others }) => {
  if (!children) return null
  return (
    <Typography
      color="neutral.60"
      variant={{ _: 'bodyMdRegular', '5xl': 'bodyLgRegular' }}
      data-testid="description"
      dangerouslySetInnerHTML={{ __html: children }}
      {...others}
    />
  )
}

Description.propTypes = propTypes
Description.defaultProps = defaultProps

export default Description
