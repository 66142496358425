import { css, styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/utilities'

import ArrowIcon from './icons/arrows.svg'

const thumbStyle = props => css`
  appearance: none;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${selectTheme('colors.neutral.0')(props)};
  background-image: url(${ArrowIcon});
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: ${selectTheme('shadow.xs')(props)};
  margin-top: -24px;
  cursor: grab;
  outline: none;
  border: none;
  position: relative;

  &:active {
    cursor: grabbing;
  }
`

const thumb = props => css`
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    ${thumbStyle(props)}
  }

  &::-moz-range-thumb {
    -moz-appearance: none;
    ${thumbStyle(props)}
  }

  &::-ms-thumb {
    ${thumbStyle(props)}
  }
`

const trackStyle = props => css`
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: linear-gradient(
    to right,
    ${selectTheme('colors.primary.40')(props)} 0%,
    ${selectTheme('colors.primary.40')(props)} ${props.filled}%,
    ${selectTheme('colors.neutral.15')(props)} ${props.filled}%,
    ${selectTheme('colors.neutral.15')(props)} 100%
  );
`

const track = props => css`
  &::-webkit-slider-runnable-track {
    ${trackStyle(props)}
  }

  &::-moz-range-track {
    ${trackStyle(props)}
  }

  &::-ms-track {
    ${trackStyle(props)}
  }
`

const StyledInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: auto;
  min-height: 60px;
  background: transparent;
  outline: none;
  overflow: visible;

  ${thumb}
  ${track}
`

export { StyledInput as Input }
