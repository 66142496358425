const getErrorMessages = (
  maxErrorMessage,
  maxErrorMessageTitle,
  minErrorMessage,
  minErrorMessageTitle,
) => {
  return [
    {
      title: minErrorMessageTitle || 'Oops! Valor insuficiente',
      message: minErrorMessage,
    },

    {
      title: maxErrorMessageTitle || 'Oops! Valor inválido',
      message: maxErrorMessage,
    },
  ]
}

export { getErrorMessages }
