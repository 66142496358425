/* eslint-disable max-lines-per-function */
import React, { useCallback } from 'react'
import { number, object, oneOf, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'
import { debounce } from 'lodash'
import { Controller, useForm } from 'react-hook-form'

import {
  normalizeNumber,
  normalizeString,
  unormalizeString,
} from '../../utils/currency'
import { getErrorMessages } from '../../utils/errors'
import {
  getValidLoanValueFromRangeConfig,
  getValidStepValueFromRangeValueConfig,
} from '../../utils/values'
import ButtonCta from '../ButtonCta'
import Errors from '../Errors'

import { Container, Input, RangeSlider, Title } from './styles'

const propTypes = {
  buttonCta: object,
  buttonCtaLabel: string,
  customStepValue: number,
  defaultLoanValue: number,
  defaultStepValue: number,
  defaultValue: number,
  max: number,
  maxErrorMessage: string,
  maxErrorMessageTitle: string,
  min: number,
  minErrorMessage: string,
  minErrorMessageTitle: string,
  product: oneOf(['HOME', 'AUTO']),
  stepErrorMessage: string,
  title: string,
}

const Form = ({
  buttonCta,
  buttonCtaLabel,
  customStepValue,
  defaultLoanValue,
  defaultStepValue,
  max,
  maxErrorMessage,
  maxErrorMessageTitle,
  min,
  minErrorMessage,
  minErrorMessageTitle,
  product: productName,
  title,
}) => {
  const { triggerEvent } = useTriggerEvents()

  const validDefaultLoanValue = getValidLoanValueFromRangeConfig(
    defaultLoanValue,
    min,
    max,
  )

  const inputValuePlaceholder = normalizeNumber(min)
  const defaultLoanValueFormatted = normalizeNumber(validDefaultLoanValue)

  const defaultValues = {
    loanValueRange: validDefaultLoanValue || min,
    loanValueInput: defaultLoanValueFormatted,
  }

  const {
    control,
    errors,
    formState,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger: triggerFormValidation,
  } = useForm({
    defaultValues,
  })

  const { isDirty } = formState

  const stepValue = getValidStepValueFromRangeValueConfig(
    customStepValue,
    defaultStepValue,
    max,
  )

  const ERROR_MESSAGES = getErrorMessages(
    maxErrorMessage,
    maxErrorMessageTitle,
    minErrorMessage,
    minErrorMessageTitle,
  )

  const debounceAnalyticEvent = debounce(
    analyticEvent => triggerEvent(analyticEvent),
    1000,
  )

  const onChangeInputValue = useCallback(
    async e => {
      const { value } = e.target
      const normalizedValue = normalizeString(value)

      setValue('loanValueInput', normalizedValue, {
        shouldValidate: true,
      })

      const numberValue = unormalizeString(normalizedValue)
      const valueForRange = numberValue

      setValue('loanValueRange', valueForRange, {
        shouldValidate: true,
      })

      debounceAnalyticEvent({
        label: `${productName} | Interaction | ${numberValue}`,
        pageInteraction: true,
      })

      await triggerFormValidation('loanValueInput')
    },
    [productName, setValue, debounceAnalyticEvent, triggerFormValidation],
  )

  const onChangeRangeValue = useCallback(
    async ({ value }) => {
      const numberValue = Number(value)
      const normalizedValue = normalizeNumber(numberValue)
      setValue('loanValueInput', normalizedValue, {
        shouldValidate: true,
      })
      debounceAnalyticEvent({
        label: `${productName} | Interaction | ${numberValue}`,
        pageInteraction: true,
      })
      await triggerFormValidation('loanValueInput')
    },
    [productName, setValue, debounceAnalyticEvent, triggerFormValidation],
  )

  const onSubmit = ({ loanValueRange }) => {
    const { url } = buttonCta || {}

    triggerEvent({
      label: `${productName} | ${loanValueRange}`,
      pageInteraction: false,
    })

    if (url) {
      const paramSymbol = url.includes('?') ? '&' : '?'
      const queryParam = `${paramSymbol}valor-emprestimo=${loanValueRange}`

      window.location.href = `${url}${queryParam}`
    }
  }

  const validateInputValue = async valueInput => {
    const value = unormalizeString(valueInput)

    const isLess = value < min
    const isGreater = value > max
    const valueErrors = [isLess, isGreater]

    const currentErrorIndex = valueErrors.findIndex(findError => findError)
    const noError = currentErrorIndex < 0

    if (!noError) {
      const errorMessage = ERROR_MESSAGES[currentErrorIndex]?.message
      debounceAnalyticEvent({
        label: `${title} | errror | ${errorMessage}`,
        pageInteraction: true,
      })
    }

    return noError || `${currentErrorIndex}`
  }

  const resetForm = () => {
    reset(defaultValues)
  }

  return (
    <Container onSubmit={e => e.preventDefault()}>
      <Title
        variant={{ _: 'bodyLgMedium', '5xl': 'bodyXlLight' }}
        htmlFor="loanValueInput"
        as="label"
      >
        {title}
      </Title>

      <Input
        name="loanValueInput"
        id="loanValueInput"
        placeholder={inputValuePlaceholder}
        inputMode="numeric"
        autoComplete="transaction-amount"
        ref={register({
          validate: value => validateInputValue(value),
          required: true,
        })}
        onChange={onChangeInputValue}
      />

      <Controller
        render={({ onChange, value: internalValue, ...field }) => (
          <RangeSlider
            value={internalValue}
            onChange={({ value }) => {
              onChange(value)
              onChangeRangeValue({ value })
            }}
            min={min}
            max={max}
            id="loanValueRange"
            step={stepValue}
            {...field}
          />
        )}
        name="loanValueRange"
        control={control}
        rules={{ required: true, min, max, valueAsNumber: true }}
      />

      <Errors
        title={ERROR_MESSAGES[errors.loanValueInput?.message]?.title}
        description={ERROR_MESSAGES[errors.loanValueInput?.message]?.message}
      />

      <ButtonCta
        {...buttonCta}
        label={
          errors.loanValueInput?.message ? 'Tentar novamente' : buttonCtaLabel
        }
        onClick={
          errors.loanValueInput?.message ? resetForm : handleSubmit(onSubmit)
        }
        disabled={!validDefaultLoanValue && !isDirty}
        productName={productName}
      />
    </Container>
  )
}

Form.propTypes = propTypes
export default Form
